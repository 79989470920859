import React, { useState, useEffect } from "react";

const ModeSelector = ({reg_tag}) => {
  const [modes, setModes] = useState([]);
  const [activeMode, setActiveMode] = useState("");
  const [loading, setLoading] = useState(true);

  // Fetch modes from the API when the component mounts
  useEffect(() => {

    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.access) {
        const requestData = { reg_tag };

        fetch("/api/get_modes/", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + user.access,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(requestData),
        })
        .then((response) => response.json())
        .then((data) => {
            console.log(data);
            setModes(data.modes);
            if (data.modes.length > 0) {
              setActiveMode(data.modes[0]);
            }
            setLoading(false);
        })
        .catch((error) => {
            console.error("Error fetching modes:", error);
            setLoading(false);
        });
    };
  }, [reg_tag]);

  const handleModeChange = (event) => {
    const selectedMode = event.target.value;
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.access) {
        const requestData = { reg_tag, mode_request: selectedMode };

        fetch("/api/set_mode/", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + user.access,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(requestData),
        })
        .then((response) => {
            if (response.status === 200) {
            setActiveMode(selectedMode);
            } else {
            throw new Error("Failed to set mode");
            }
        })
        .catch((error) => {
            console.error("Error setting mode:", error); 
        });
    };
};

  return (
    <div>
        <h3>Prompt Mode</h3>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="ModeSelector" 
            style={{display:'flex', gap:'5px', flexDirection:'column', width:'6rem'}}>
            
            <select value={activeMode} 
                style={{
                    fontSize: 'small',
                    padding: '5px',
                    borderRadius: '10px',
                    outline: 'none',
                    textAlign: 'center',
                }}
                onChange={handleModeChange}>
            {modes.map((mode, index) => (
                <option key={index} value={mode}>
                {mode}
                </option>
            ))}
            </select>
        </div>
      )}
    </div>
  );
};

export default ModeSelector;
